import { css } from 'astroturf';
import React, { Component } from 'react';
import { Checkmark, Close } from 'react-bytesize-icons';
import Logo from '../../../assets/images/icon.svg';
import CTA from '../../components/CTA';
import CalculatorCTA from '../../components/CalculatorCTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .sidebyside {
    padding: 0 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem auto 2rem;
    max-width: 400px;

    @media (min-width: 800px) {
      flex-direction: row;
      max-width: initial;
    }
  }

  .vs {
    align-self: center;
    aspect-ratio: 1;
    position: relative;
    display: grid;
    place-items: center;
    width: max-content;
    padding: 0.5rem 1rem;
    font-size: 1.75rem;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 20px 30px;
    color: #fff;
    background: #4ca2cd linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    flex: 0 0 72px;
    margin: -1rem auto;

    @media (min-width: 800px) {
      margin: 0 -1.5rem;
    }
  }

  .alt {
    width: min(400px, 100%);
    background: #fff;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 20px 30px;
    padding: 2rem 1.5rem 2rem;
    border-radius: 0.5rem;
    text-align: center;

    & > svg,
    & > div > div > svg {
      display: block;
      margin: 0 auto;
      width: 64px;
      height: 64px;
    }

    & ul {
      text-align: left;
      margin: 2rem;
      list-style: none;
      padding: 0;

      & li {
        position: relative;
      }

      & svg {
        margin-left: 0;
        position: absolute;
        left: -22px;
        top: 4px;
        background: #67b26f;
        stroke: #fff;
        border-radius: 100%;
        padding: 2px;
        vertical-align: text-bottom;

        &.notok {
          background: #b2676f;
        }
      }
    }
  }

  .price {
    font-weight: 900;
    font-size: 1.25rem;
    color: rgb(17, 17, 51);
    text-align: left;
    margin: 0 auto 1rem;
    width: max-content;

    & small {
      display: block;
      font-weight: normal;
      color: rgb(44, 130, 173);
    }

    & span {
      position: absolute;
      bottom: 1.5rem;
      font-size: 0.8em;
      left: 0;
      width: 100%;
      text-align: center;
      font-weight: normal;
    }
  }

  .title {
    text-align: center;
    font-weight: 900;
    padding: 0 2rem;
    margin-top: 5rem;
    margin-bottom: -2rem;
  }
`;

const Check = () => <Checkmark className={styles.ok} height={14} width={14} strokeWidth="15%" id="" />;
const NotCheck = () => <Close className={styles.notok} height={14} width={14} strokeWidth="15%" id="" />;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Polypane alternatives 2024 (free and paid)"
          description="Compare Polypane to the alternatives."
          keywords={['']}
          pathname={this.props.location.pathname}
        />
        <PageHeader>
          <h1 style={{ zIndex: 1, position: 'relative' }}>
            <svg
              className={styles.icon}
              width="137"
              height="60"
              viewBox="0 0 137 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient id="sqgradient" gradientTransform="rotate(45)">
                  <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                  <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
                </linearGradient>
              </defs>
              <rect width="53" height="60" rx="8" fill="url(#sqgradient)" />
              <rect x="60" width="43" height="50" rx="8" fill="url(#sqgradient)" />
              <rect x="110" width="27" height="37" rx="8" fill="url(#sqgradient)" />
            </svg>
            <br />
            The alternatives to Polypane
          </h1>
          <h2>
            Polypane replaces a bunch of devices and services. <strong>See how Polypane compares</strong> to common
            responsive design testing methods and services.
          </h2>
          <p>
            <strong>Polypane is a desktop browser for people that make websites.</strong> It runs on your computer and
            emulates your site running on multiple devices. You can inspect, edit and audit your site live. It includes
            hundreds of tools to help improve your site.
          </p>
        </PageHeader>
        <div className={styles.sidebyside}>
          <div className={styles.alt}>
            <Logo />
            <strong>Polypane</strong>
            <ul>
              <li>
                <Check /> Flat fee
              </li>
              <li>
                <Check /> Always up to date
              </li>
              <li>
                <Check /> Test multiple devices <Link to="/docs/synced-interactions/">simultaneously</Link>
              </li>
              <li>
                <NotCheck /> <Link to="/docs/emulation/">Emulated</Link>, fast devices
              </li>
              <li>
                <NotCheck /> 95% accurate rendering
              </li>
            </ul>
            <div className={styles.price}>
              <small>just</small> $9/month.{' '}
              <span>
                <Link to="/pricing/">Get it now</Link>
              </span>
            </div>
          </div>
          <div className={styles.vs}>VS</div>
          <div className={styles.alt}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-browser-check"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="rgb(44, 130, 173)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="4" width="16" height="16" rx="1" />
              <path d="M4 8h16" />
              <path d="M8 4v4" />
              <path d="M9.5 14.5l1.5 1.5l3 -3" />
            </svg>
            <strong>Online device emulation</strong>
            <ul>
              <li>
                <NotCheck /> Limited to devices offered
              </li>
              <li>
                <NotCheck /> Test one device at a time
              </li>
              <li>
                <NotCheck /> Slow
              </li>
              <li>
                <NotCheck /> Just emulation
              </li>
              <li>
                <Check /> 100% accurate rendering
              </li>
            </ul>

            <div className={styles.price}>
              <small>starts at</small> $29/month
            </div>
          </div>
        </div>

        <div className={styles.sidebyside}>
          <div className={styles.alt}>
            <Logo />
            <strong>Polypane</strong>
            <ul>
              <li>
                <Check /> No limit to emulated devices
              </li>
              <li>
                <Check /> Test multiple devices simultaneously
              </li>
              <li>
                <Check /> Local, as fast as your computer
              </li>
              <li>
                <Check /> Emulation, <Link to="/docs/meta-information/">Live editing and inspection</Link>
              </li>
              <li>
                <NotCheck /> Single rendering engine
              </li>
            </ul>
            <div className={styles.price}>
              <small>just</small> $9/month.{' '}
              <span>
                <Link to="/pricing/">Get it now</Link>
              </span>
            </div>
          </div>
          <div className={styles.vs}>VS</div>
          <div className={styles.alt}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-devices"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="rgb(44, 130, 173)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="13" y="8" width="8" height="12" rx="1" />
              <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
              <line x1="16" y1="9" x2="18" y2="9" />
            </svg>
            <strong>Buying real devices</strong>
            <ul>
              <li>
                <NotCheck /> Gets real expensive, real fast
              </li>
              <li>
                <NotCheck /> Still only test them one-by-one
              </li>
              <li>
                <NotCheck /> Need to keep them all up-to-date
              </li>
              <li>
                <Check /> Actual devices
              </li>
              <li>
                <Check /> Use them for other things
              </li>
            </ul>
            <div className={styles.price}>
              <small>starts at</small> $500/year
            </div>
          </div>
        </div>

        <div className={styles.sidebyside}>
          <div className={styles.alt}>
            <Logo />
            <strong>Polypane</strong>
            <ul>
              <li>
                <Check /> Works everywhere
              </li>
              <li>
                <Check /> Catch issues before your customer
              </li>
              <li>
                <Check /> No wasted time
              </li>
              <li>
                <Check /> Quick development (always)
              </li>
              <li>
                <Check /> Hundreds of features (<Link to="/docs/">Docs</Link>)
              </li>
            </ul>
            <div className={styles.price}>
              <small>just</small> $9/month.{' '}
              <span>
                <Link to="/pricing/">Get it now</Link>
              </span>
            </div>
          </div>
          <div className={styles.vs}>VS</div>
          <div className={styles.alt}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-circle-dashed"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="rgb(44, 130, 173)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
              <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
              <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
              <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
              <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
              <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
              <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
              <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
            </svg>
            <strong>No responsive testing</strong>
            <ul>
              <li>
                <NotCheck /> Works on my machine
              </li>
              <li>
                <NotCheck /> Your customers are your QA
              </li>
              <li>
                <i>
                  <NotCheck /> So much wasted time
                </i>
              </li>
              <li>
                <Check /> Quick development (initially, anyway)
              </li>
              <li>
                <Check /> YOLO
              </li>
            </ul>
            <div className={styles.price}>
              <small>starts at</small> free{' '}
              <span>
                (and then $<i>x</i>000s/year)
              </span>
            </div>
          </div>
        </div>

        <h2 className={styles.title}>Need help convincing your employer?</h2>
        <CalculatorCTA />
        <div style={{ marginBottom: '8rem' }} />
        <CTA />
        <div style={{ marginBottom: '8rem' }} />
      </Layout>
    );
  }
}

export default Page;
