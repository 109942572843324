import { css } from 'astroturf';
import React, { Component } from 'react';

import Link from './link';

import ExpenseInfoBox from './pricetable/ExpenseInfoBox';

const styles = css`
  .wrap {
    padding: 0 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin-top: 4rem;
  }
  .cta {
    text-align: left;
    max-width: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    background: rgb(255, 255, 255);
    box-shadow:
      rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.05) 0px 1px 0px,
      rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 20px 30px;
    padding: 1rem;
    border-radius: 0.5rem;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @media (min-width: 480px) {
      flex-direction: row;

      & > div {
        text-align: left;
        display: block;
      }
    }

    & svg {
      flex: 0 0 128px;
      transform: rotate(-8deg);
    }

    & h2 {
      font-size: 26px;
      line-height: 1.1;
      margin-bottom: 0.5rem;
    }

    & h2 :is(b, strong) {
      font-weight: 900;
      background: #67b26f linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }

    & a {
      margin: 0;
      position: relative;
      background: #67b26f linear-gradient(135deg, #67b26f, #67b26f);
      text-align: center;
      font-weight: 600;
      color: #fff;
      border: 0;
      border-radius: 2.5rem;
      width: 100%;
      height: 2.5rem;
      transition: background 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;
      max-width: 260px;
      margin-top: 1rem;

      display: grid;
      place-items: center;
      white-space: nowrap;

      @media (min-width: 400px) {
        margin-left: -4px;
      }

      &:hocus {
        &::before {
          opacity: 1;
        }
      }

      & span {
        position: relative;
        z-index: 1;
      }

      &::before {
        will-change: opacity;
        content: ' ';
        display: block;
        background: linear-gradient(135deg, #4ca2cd, #67b26f);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        border-radius: 7px;
      }
    }
  }
`;

class CalculatorCTA extends Component {
  render() {
    return (
      <div className={styles.wrap}>
        <ExpenseInfoBox />
        <div className={styles.cta}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-receipt-2"
            width="128"
            height="128"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#000000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
            <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" />
          </svg>
          <div>
            <h2>
              Your time is <strong>valuable</strong>.
            </h2>
            <p>
              Find out how many <strong>hours</strong> you can save each month.
            </p>
            <Link to="/calculator/" className={styles.button}>
              <span>Calculate your time saved</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatorCTA;
